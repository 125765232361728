<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      asset_valuation: {},
      openingBalances: [],
      levelone: [],
      name: "",
      amount: [],
      letwo: [],
      edit: {},
      data: [{}],

      // appprices:[]
    };
  },
  methods: {
    editTd(asset) {
      console.log(this.data);
      var edit = document.querySelectorAll(".one" + asset.id);

      console.log(edit);
      for (let i = 0; i < edit.length; i++) {
        edit[i].innerHTML = "";
      }
    },

    editable() {
      console.log(this.editTd);
      this.editTd = true;
    },
    get() {
      this.http.post("transactions/assets-valuation", {}).then((res) => {
        this.asset_valuation = res.data;
        var tot = 0;

        for (
          let index = 0;
          index < this.asset_valuation.level_one_chart_of_accounts.length;
          index++
        ) {
          this.name =
            this.asset_valuation.level_one_chart_of_accounts[index].name;
          console.log(this.levelone);
          for (
            let levelOneIndex = 0;
            levelOneIndex <
            this.asset_valuation.level_one_chart_of_accounts[index]
              ?.level_two_chart_of_accounts.length;
            levelOneIndex++
          ) {
            //  this.letwo[index]=(this.asset_valuation.level_one_chart_of_accounts[index]?.level_two_chart_of_accounts[levelOneIndex]?.name)
            for (
              let levelTrhee = 0;
              levelTrhee <
              this.asset_valuation.level_one_chart_of_accounts[index]
                ?.level_two_chart_of_accounts[levelOneIndex]
                ?.level_three_chart_of_accounts.length;
              levelTrhee++
            ) {
              for (
                let openBalanceIndex = 0;
                openBalanceIndex <
                this.asset_valuation.level_one_chart_of_accounts[index]
                  .level_two_chart_of_accounts[levelOneIndex]
                  ?.level_three_chart_of_accounts[levelTrhee]?.opening_balances
                  .length;
                openBalanceIndex++
              ) {
                this.amount = parseFloat(
                  this.asset_valuation.level_one_chart_of_accounts[index]
                    .level_two_chart_of_accounts[levelOneIndex]
                    .level_three_chart_of_accounts[levelTrhee].opening_balances[
                    openBalanceIndex
                  ]?.amount
                );

                tot += parseFloat(
                  this.asset_valuation.level_one_chart_of_accounts[index]
                    .level_two_chart_of_accounts[levelOneIndex]
                    .level_three_chart_of_accounts[levelTrhee].opening_balances[
                    openBalanceIndex
                  ]?.amount
                );
                console.log(
                  this.asset_valuation.level_one_chart_of_accounts[index]
                    .level_two_chart_of_accounts[levelOneIndex]
                    .level_three_chart_of_accounts[levelTrhee].opening_balances[
                    openBalanceIndex
                  ]?.amount
                );
                console.log(this.levelone);
              }
            }
            console.log(this.amount);
          }

          this.levelone.push({ name: this.name, tot: tot });

          console.log(this.levelone);

          this.asset_valuation.level_one_chart_of_accounts[index]["tot"] = tot;
          tot = 0;
        }

        // this.asset_valuation.level_one_chart_of_accounts.forEach(asset => {

        //    asset.amount=0
        //    return  asset.level_two_chart_of_accounts.forEach(levelTwo=>{
        //     return levelTwo.level_three_chart_of_accounts.forEach(levelthree=>{
        //       asset.amount=levelthree.opening_balances.forEach(amount=>{

        //         return  this.opening_balances+=parseFloat(amount.amount)
        //       })

        //     })
        //   })

        // });

        console.log(this.asset_valuation);
      });
    },
  },
  created() {
    this.get();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menu.menuitems.accounting.subItems.asset-valuation')" />

    <br /><br /><br />

    <table class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col"></th>
          <th scope="col">{{ $t("asset_valuation.accounting-value") }}</th>
          <th scope="col">{{ $t("asset_valuation.corrections") }}</th>
          <th scope="col">{{ $t("asset_valuation.market-value") }}</th>
          <!-- <th scope='col'>{{ $t("asset_valuation.operations") }}</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="asset in asset_valuation.level_one_chart_of_accounts" :key="asset" class="text-center">
          <td>
            <span class="fw-bold">{{ asset.name }}</span>
        <tr v-for="le2 in asset.level_two_chart_of_accounts" :key="le2">
          <span>{{ le2.name }}</span>
        </tr>
        </td>

        <td>
          <span class="fw-bold me-1 text-cnter ms-5">{{ asset.tot }}</span>
          <tr v-for="level in asset.level_two_chart_of_accounts" :key="level">
            <span class="text-center ms-5" v-for="levelthree in level.level_three_chart_of_accounts" :key="levelthree">
          <tr v-for="amount in levelthree.opening_balances" :key="amount">
            {{
              amount.amount
            }}
          </tr>
          </span>
          </tr>
        </td>
        <!-- <td>
          <span class="fw-bold me-1 text-cnter "></span>
          <tr v-for="level in asset.level_two_chart_of_accounts" :key="level">
            <span class="text-center ms-5 " v-for="levelthree in level.level_three_chart_of_accounts" :key="levelthree">
          <tr v-if="!level.v1" @click="level.v1 = true" v-for="amount in levelthree.opening_balances" :key="amount">0</tr>
          <div v-else>
            <input class="form-control" id="exampleInputPassword1">


          </div>
          </span>

          </tr>

        </td> -->
        <td>
          <span class="fw-bold me-1 text-cnter"></span>
          <tr v-for="level in asset.level_two_chart_of_accounts" :key="level">
            <span class="text-center ms-5" v-for="levelthree in level.level_three_chart_of_accounts" :key="levelthree">
          <tr @click="editable()" v-for="amount in levelthree.opening_balances" :key="amount">
            0
          </tr>
          </span>
          </tr>
        </td>
        <!-- <td class="text-center">
        <span class="fw-bold text-center "></span>
        <tr v-for="level in asset.level_two_chart_of_accounts" :key="level">
          <div>
         <div>
          <span   class="text-center">0</span><br>
         </div>
    
         <div v-if="editTd==true">
          <input  class="form-control" id="exampleInputPassword1">
    
           
         </div></div>
        </tr>
    
    
        </td> -->
        <!--	Market Value-->
        <!-- <td class="text-center">
            <span class="fw-bold text-center "></span>
            <tr v-for="level in asset.level_two_chart_of_accounts" :key="level" >
              <span  class="text-center ms-5" :class="'one'+asset.id" :id="asset.id">0</span><br></tr></td> -->
        <!-- <td class="text-center"><button type="button" class="btn btn-primary"  @click="editable(asset)" >Edit</button></td> -->
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<style scoped>
td,
th {
  text-align: left;
  padding: 8px;
}
</style>




